import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
const carousels = require("./carousels");

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    isOpen: false,
  },
  mounted() {
    this.opening();
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
  },
  methods: {
    opening() {
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.bgMovie.play();
      });
    },
  },
});
